<template>
  <div>
    <b-card>
    <b-row>
      <b-col cols="12">
      <span>
      企业当前的通知内容和处理情况
      </span>
      </b-col>
    </b-row>
    <br/>   
    <b-row>
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="list"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
        >
          <template #cell(avatar)="data">
            <b-avatar :src="data.value" />
          </template>
          <template #cell(action)="data">
            <div class="text-center">
              <b-button variant="flat-danger" @click="onDelete(data.item)">
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span class="align-center">删除</span></b-button>
            </div>
          </template>
        </b-table>
      </b-col>
      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    </b-card>
  </div>

</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from "bootstrap-vue";

export default {
  name: "Notifications",

  components: {
    BTable,
    BAvatar,
    BBadge,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },

  data() {
    return {
      list: [],
      total: 0,

      currentPage: null,
      totalRows: null,
      perPage: 30,
      sortBy: null,
      sortDesc: null,
      sortDirection: null,
      fields: [
        { key: "id", label: "编号" },
        { key: "title", label: "标题" },
        { key: "content", label: "内容" },
        { key: "type", label: "消息类型" },
        { key: "status", label: "状态" },
        { key: "createdAt", label: "日期" },
        { key: "action", label: "操作" },
      ],
    };
  },

  beforeCreate() {
    this.$http.get("/notifications").then((res) => {
      this.list = res.data.data.list;
      this.total = res.data.data.total;
    });
  },

  methods: {
    reloadData() {
      this.$http.get("/notifications").then((res) => {
        this.list = res.data.data.list;
        this.total = res.data.data.total;
      });
    },

    onDelete(item) {
      this.$http.delete("/notifications/" + item.id).then((res) => {
        this.reloadData();
      });
    },
  },
};
</script>